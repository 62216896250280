import { combineReducers } from '@reduxjs/toolkit';
import { invoicesReducer, invoiceReducer } from './invoiceReducer';
import auditReducer from './AuditReducer';
import installmentDetailsReducer from './InstallmentDetailsReducer';
import scheduledInstallmentsReducer from './ScheduledInstallmentsReducer';
import userReducer from './userReducer';
import snackBar from './snackBarReducer';

const rootReducer = combineReducers({
  invoices: invoicesReducer,
  invoice: invoiceReducer,
  audits: auditReducer,
  user: userReducer,
  snackBar,
  installmentDetails: installmentDetailsReducer,
  scheduledInstallments: scheduledInstallmentsReducer,
});

export default rootReducer;
