import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings/dev";
import httpRequest from "../utils/api";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { displayError } from "./snackBarReducer";
const initialState = {
  name: null,
  email: null,
  picture: null,
  loggedIn: false,
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.picture = action.payload.picture;
      state.loggedIn = true;
      return state;
    },
    loggedOut: (state, action) => {
      state.name = null;
      state.email = null;
      state.picture = null;
      state.loggedIn = false;
      return state;
    },
  },
});

export const getUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { dispatch }) => {
    try{
    // Check if user data is in localStorage
    if (localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user"));
      dispatch(setUser(userData));
      return userData;
    }

    const url = `${baseURL}/user`;
    const response = await httpRequest("get", url);
    const userData = response;

    localStorage.setItem("user", JSON.stringify(userData));

    dispatch(setUser(userData));

    return userData;
  }catch(error){
    dispatch(
      displayError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "something went wrong"
      )
    );
  }
  }
);


export const logOut = () => async (dispatch) => {
  localStorage.removeItem("user");
  window.location.href = "/logout"
  return null;
};

export const { setUser, loggedOut } = userReducer.actions;

export default userReducer.reducer;
