import React from 'react';

export const renderText = (text) => {
  if (text == null || text === '') return 'No Message';
  const words = text.split(' ');
  const lines = [];
  let line = '';

  words.forEach((word, index) => {
    if ((index + 1) % 10 === 0) {
      lines.push(line.trim());
      line = '';
    }
    line += `${word} `;
  });

  if (line.trim() !== '') {
    lines.push(line.trim());
  }

  return lines.map((line, index) => (
    <div key={index}>{line}</div>
  ));
};

export const encodeOffset = (offset) => {
  return offset != null ? encodeURIComponent(offset.replace(/\\/g, "")) : null;
};
