import { React, useState, useCallback, useEffect } from "react";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { listAudits, setOffsetList } from "../../reducers/AuditReducer";
import { displayError } from "../../reducers/snackBarReducer";
import moment from "moment";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import LinearProgress from "@mui/material/LinearProgress";
import { encodeOffset } from "../../utils/textProcessor";

export default function TableView({ jsonData, columns, id : getRowId}) {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const isLoading = useSelector((state) => state.audits.loading);
  const rows = useSelector((state) => state.audits.audits);

  const totalDataCount = useSelector((state) => state.audits.length);
  const rowCountState =
    useSelector((state) => state.audits.offset) != null
      ? (paginationModel.page + 1) * totalDataCount + 1
      : (paginationModel.page + 1) * 10 - (10 - totalDataCount);

  const offsetList = useSelector((state) => state.audits.offsetList);
  const currentOffset = useSelector((state) => state.audits.offset);

  const updatePaginationModel = (updatedFields) => {
    setPaginationModel((prevState) => ({
      ...prevState,
      ...updatedFields,
    }));
  };

  // Define a custom theme
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Set primary color to black
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontFamily: '"Poppins", sans-serif',
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              color: "black",
            },
          },
        },
      },
    },
  });

  const onFilterChange = useCallback(
    (filterModel) => {
      setPaginationModel({page:0})
      setFilterState([]);
      dispatch(setOffsetList([null]));
      if (
        filterModel &&
        filterModel.items[0] &&
        filterModel.items[0].value !== undefined
      ) {
        let value = filterModel.items[0].value;
        if (value === null) {
          return;
        }
        let key = filterModel.items[0].field;
        let operator = filterModel.items[0].operator;
        const tempList = []
        if (key === "occurred_at" && operator === "between") {
          let startValue = moment(value.start).format("YYYY-MM-DD HH:mm:ss");
          let endValue = moment(value.end).format("YYYY-MM-DD HH:mm:ss");
          tempList.push({key:"start_occurred_at", value:startValue},{key:"end_occurred_at", value:endValue})
        }
        if (key === "occurred_at" && operator === "is") {
          value = moment(value).format("YYYY-MM-DD HH:mm:ss");
          tempList.push({key:"occurred_at", value:value})
        }
        if(tempList.length === 0){
          tempList.push({key:key, value:value})
        }
        setFilterState(tempList);
        dispatch(listAudits(tempList, null));
      } else {
        dispatch(listAudits(null, null));
      }
    },
    [dispatch]
  );

  const CustomToolbar = () => {
    return (
      <div className="mb-5">
        <GridToolbarContainer>
          <GridToolbarExport
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{ disableToolbarButton: true }}
          />
          <GridToolbarFilterButton />
        </GridToolbarContainer>

        <div className="border-b border-moove-orange "></div>
      </div>
    );
  };

  const CustomPagination = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className={`mr-7 hover:cursor-pointer border-2 border-black rounded-full p-1 ${
            paginationModel.page === 0
              ? "opacity-50 hover:cursor-not-allowed"
              : ""
          }`}
          onClick={() =>
            paginationModel.page !== 0 &&
            updatePaginationModel({ page: paginationModel.page - 1 })
          }
        >
          <GrPrevious />
        </div>
        <div
          className={`mr-7 hover:cursor-pointer border-2 border-black rounded-full p-1 ${
            currentOffset === null ? "opacity-50 hover:cursor-not-allowed" : ""
          }`}
          onClick={() =>
            currentOffset !== null &&
            updatePaginationModel({ page: paginationModel.page + 1 })
          }
        >
          <GrNext />
        </div>
      </div>
    );
  };

  // Function to fetch data when paginationModel changes
  useEffect(() => {
      try {
        dispatch(listAudits(filterState, encodeOffset(offsetList[paginationModel.page])));
      } catch (error) {
        dispatch(displayError("Error fetching data:" + error));
      }
  }, [paginationModel.page]);

  return (
    <ThemeProvider theme={theme}>
      {rows && (
        <DataGrid
          rows={jsonData}
          columns={columns}
          loading={isLoading}
          rowCount={rowCountState}
          pageSizeOptions={[5]}
          paginationModel={paginationModel}
          paginationMode="server"
          rowThreshold={4}
          onPaginationModelChange={setPaginationModel}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 4,
            bottom: params.isLastVisible ? 0 : 4,
          })}
          checkboxSelection={false}
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
            pagination: CustomPagination,
          }}
          getRowId={getRowId}
          rowReordering
          sx={{
            padding: "20px",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            border: 0,

            "& .MuiDataGrid-columnHeader--filtered": {},
            "& .super-app-theme--header": {
              bgcolor: "#e5e7eb",
              color: "#374151",
            },
            bgcolor: "neutral",
          }}
          showCellVerticalBorder={false}
          disableDensitySelector
          disableColumnSelector
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
            },
          }}
          filterMode="server"
          filterDebounceMs={1500}
          onFilterModelChange={onFilterChange}
          className="lg:w-auto shadow-md shadow-gray-400 rounded-lg "
        />
      )}
    </ThemeProvider>
  );
}
