import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  content: 'null',
  severity: 'error',
  vertical: 'bottom',
  horizontal: 'right',
  timeout: 6000,
};
const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.visible = true;
      state.content = action.payload.message;
      if (action.payload.severity === 'success') {
        state.severity = 'success';
      } else {
        state.severity = 'error';
      }
    },
    hideSnackbar: (state) => {
      state.visible = false;
      state.content = null;
    },
  },
});

export const displayError = (error) => (dispatch) => {
  if (error) dispatch(showSnackbar({ message: error }));
};

export const displaySuccess = (msg) => (dispatch) => {
  if (msg) dispatch(showSnackbar({ message: msg, severity: 'success' }));
};

export const displayResendVerification = (msg) => (dispatch) => {
  if (msg) dispatch(showSnackbar({ message: msg, severity: 'success' }));
};

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
