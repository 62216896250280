export const getCurrencySign = (currencyCode) => {
  if (currencyCode == null || currencyCode === '') return '';

  if (currencyCode.toUpperCase() === 'USD') return '$';
  if (currencyCode.toUpperCase() === 'INR') return '₹';
  if (currencyCode.toUpperCase() === 'GBP') return '£';
  return currencyCode.toUpperCase();
};

export const formatCurrency = (value, currencyCode) => {
  if (value == null || currencyCode == null) return '';
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    unitDisplay: 'long',
  });
  return formatter.format(value);
};
