import React from "react";
import TableViewInstallmentDetails from "../../components/Audit/InstallmentDetailsTableView";
import { useSelector } from "react-redux";
import { getGridStringOperators } from "@mui/x-data-grid";
import { DatePicker } from "rsuite";
import { DateRangePicker } from "rsuite";
import { formatCurrency } from "../../utils/currencyUtil";
import { formatDateInTimezone } from "../../utils/timezone";
import "rsuite/DateRangePicker/styles/index.css";

function DateRangeInputValue(props) {
  const { item, applyValue } = props;
  const [selectedRange, setSelectedRange] = React.useState([]);

   React.useEffect(() => {
    if (item.value && item.value.start && item.value.end) {
      setSelectedRange([item.value.start, item.value.end]);
    }
  }, [item.value]);

  const handleDateChange = (event) => {
    if (event && event[0] !== null && event[1] !== null) {
      setSelectedRange(event);
      applyValue({ ...item, value: { start: event[0], end: event[1] } });
    }else {
      setSelectedRange([]); 
      applyValue({ ...item, value: null }); 
    }
  };

  return (
    <>
      <DateRangePicker
        value={selectedRange}
        format="yyyy-MM-dd HH:mm:ss"
        editable={false}
        onChange={handleDateChange}
        className="mt-3"
      />
    </>
  );
}

function DateInputValue(props) {
  const { item, applyValue } = props;
  const [selectedDate, setSelectedDate] = React.useState(item.value);

  const handleDateChange = (event) => {
    setSelectedDate(event);
    applyValue({ ...item, value: event });
  };

  return (
    <>
      <DatePicker
        value={selectedDate}
        format="yyyy-MM-dd"
        editable={false}
        onChange={handleDateChange}
        className="mt-3"
      />
    </>
  );
}
function InstallmentDetails() {
  const getRowId = (row) => row.id;
  const jsonData = useSelector((state) => state.installmentDetails.audits);

  const dateBetweenOperators = [
    {
      label: "between",
      value: "between",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
      },
      InputComponent: DateRangeInputValue,
      InputComponentProps: {},
      getValueAsString: (value) => `${value[0]}`,
    },
    {
      label: "is",
      value: "is",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
      },
      InputComponent: DateInputValue,
      getValueAsString: (value) => `${value[0]}`,
    },
  ];

  const columns = [
    {
      field: "invoice_number",
      headerName: "Invoice Id",
      sortable: false,
      width: 100,
      headerClassName: "super-app-theme--header",
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell: (params) => (
        <span className="ml-4 font-semibold ">{params.value}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      headerClassName: "super-app-theme--header",
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell: (params) => (
        <span
          className={`text-xs font-semibold px-2.5 py-0.5 rounded border ${
            params.value === "SUCCESS"
              ? "text-green-700 border-green-400"
              : "text-moove-orange border-orange-400"
          }`}
        >
          {params.value.toUpperCase().replace("_", " ")}
        </span>
      ),
      filterable: true,
    },
    {
      field: "installment_config_id",
      headerName: "Installment Config Id",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "installment_id",
      headerName: "Installment Id",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "installment_date",
      headerName: "Installment Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => formatDateInTimezone(params.value),
      filterable: true,
      filterOperators: dateBetweenOperators,
    },
    {
      field: "installment_amount",
      headerName: "Installment Amount",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) =>
        formatCurrency(Number(params.value) / 100, params.row?.currency_code),
      filterable: false,
    },
    {
      field: "cb_transaction_id",
      headerName: "Cb Transaction Id",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "cb_transaction_status",
      headerName: "Cb Transaction Status",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
  ];

  return (
    <div className="flex-grow pl-5 pr-5 md:pl-10 md:pr-10 h-screen max-w-max overflow-y-auto">
      <TableViewInstallmentDetails
        jsonData={jsonData}
        columns={columns}
        id={getRowId}
      ></TableViewInstallmentDetails>
    </div>
  );
}

export default InstallmentDetails;
