import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings/dev";
import { setLoading } from "./invoiceReducer";
import httpRequest from "../utils/api";
import { displayError } from "./snackBarReducer";
/**
 * AUDIT FORMAT:
 * {
       id: "1",
       invoice_id: "inv_124",
       new_subscription_id: "16CRs0U2kUOKN1zAE",
       user: "mark",
       date: 1704188079,
       billing_cycle: 3,
       start_date: 1704188079,
     }
 */
const initialState = {
  loading: true,
  offset: null,
  audits: [],
  length: 0,
  offsetList: [null],
};

const auditReducer = createSlice({
  name: "audits",
  initialState,
  reducers: {
    setAudits: (state, action) => {
      state.audits = action.payload.list;
      state.loading = false;
      state.offset = action.payload.next_offset;
      state.length = state.audits.length;
      if (!state.offsetList.includes(action.payload.next_offset))
        state.offsetList.push(action.payload.next_offset);
      return state;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    removeOffset: (state, action) => {
      if (action.payload < state.offsetList.length)
        state.offsetList = state.offsetList.slice(0, -1);
    },
    setOffsetList: (state, action) => {
      state.offsetList = action.payload;
    },
  },
});


export const listAudits = (filter = [], offset = null) => async (dispatch) => {
  try {
    let url = `${baseURL}/listAudits?`;
    if (filter && filter.length > 0) {
      filter.forEach((item) => {
        url += `${item.key}=${encodeURIComponent(item.value)}&`;
      });
    }

    if (offset != null) {
      url += `next_offset=${offset}`;
    }

    dispatch(setLoading(true));
    const response = await httpRequest("get", url);
    const data = response;
    dispatch(setAudits(response));
    return data;
  } catch (error) {
    dispatch(
      displayError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong"
      )
    );
  }
};


export const { setAudits, removeOffset, setOffsetList } = auditReducer.actions;

export default auditReducer.reducer;
