import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings/dev";
import { setLoading } from "./invoiceReducer";
import httpRequest from "../utils/api";
import { displayError } from "./snackBarReducer";

/**
 * SCHEDULED INSTALLMENTS FORMAT:
 * {
      "id": 8,
      "invoice_number": "872",
      "status" : "scheduled/scheduling_failed/executed/execution_failed",
      "scheduled_date" : "",
      "action_date" : "",
      "error_message": ""
     }
 */
const initialState = {
  loading: true,
  offset: null,
  audits: [],
  length: 0,
  offsetList: [null],
};

const scheduledInstallmentsReducer = createSlice({
  name: "scheduledInstallments",
  initialState,
  reducers: {
    setScheduledInstallments: (state, action) => {
      state.audits = action.payload.list;
      state.loading = false;
      state.offset = action.payload.next_offset;
      state.length = state.audits.length;
      if (!state.offsetList.includes(action.payload.next_offset))
        state.offsetList.push(action.payload.next_offset);
      return state;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    removeOffset: (state, action) => {
      if (action.payload < state.offsetList.length)
        state.offsetList = state.offsetList.slice(0, -1);
    },
    setOffsetList: (state, action) => {
      state.offsetList = action.payload;
    },
  },
});

    export const listScheduledInstallments =
    (filter = [], offset = null) =>
    async (dispatch) => {
      try {
        var url = `${baseURL}/listScheduledInstallments?`;
        if (filter && filter.length > 0) {
          filter.forEach((item) => {
        url += `${item.key}=${encodeURIComponent(item.value)}&`;
      });
        }
        if (offset != null) {
          url = url + `&next_offset=${offset}`;
        }

        dispatch(setLoading(true));
        const response = await httpRequest("get", url);
        const data = response;
        dispatch(setScheduledInstallments(response));
        return data;
      } catch (error) {
        dispatch(
          displayError(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "something went wrong"
          )
        );
      }
    };

export const { setScheduledInstallments, removeOffset, setOffsetList } =
  scheduledInstallmentsReducer.actions;

export default scheduledInstallmentsReducer.reducer;
