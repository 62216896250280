import axios from 'axios';

async function httpRequest(method, url, data = null) {
    let response;
    switch (method.toLowerCase()) {
      case 'get':
        response = await axios.get(url);
        break;
      case 'post':
        response = await axios.post(url, data);
        break;
      case 'delete':
        response = await axios.delete(url);
        break;
      default:
        throw new Error('Invalid HTTP method');
    }
    return response.data;
  } 

export default httpRequest;
