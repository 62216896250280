import React, { useState } from "react";
import TableViewAudit from "../../components/Audit/AuditTableView";
import { useSelector } from "react-redux";
import { getGridStringOperators } from "@mui/x-data-grid";
import { DatePicker } from "rsuite";
import { DateRangePicker } from "rsuite";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import ViewIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { renderText } from "../../utils/textProcessor";
import { formatCurrency } from "../../utils/currencyUtil";
import { formatDateInTimezone } from "../../utils/timezone";
import "rsuite/DateRangePicker/styles/index.css";

function DateRangeInputValue(props) {
  const { item, applyValue } = props;
  const [selectedRange, setSelectedRange] = React.useState([]);


   React.useEffect(() => {
    if (item.value && item.value.start && item.value.end) {
      setSelectedRange([item.value.start, item.value.end]);
    }
  }, [item.value]);

  const handleDateChange = (event) => {
    if (event && event[0] !== null && event[1] !== null) {
      setSelectedRange(event);
      applyValue({ ...item, value: { start: event[0], end: event[1] } });
    }else {
      setSelectedRange([]); 
      applyValue({ ...item, value: null }); 
    }
  };

  return (
    <>
      <DateRangePicker
        value={selectedRange}
        format="yyyy-MM-dd HH:mm:ss"
        editable={false}
        onChange={handleDateChange}
        className="mt-3"
      />
    </>
  );
}

function DateInputValue(props) {
  const { item, applyValue } = props;
  const [selectedDate, setSelectedDate] = React.useState(item.value);

  const handleDateChange = (event) => {
    setSelectedDate(event);
    applyValue({ ...item, value: event });
  };

  return (
    <>
      <DatePicker
        value={selectedDate}
        format="yyyy-MM-dd"
        editable={false}
        onChange={handleDateChange}
        className="mt-3"
      />
    </>
  );
}
function Audits() {
  const getRowId = (row) => row.id;
  const jsonData = useSelector((state) => state.audits.audits);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleViewClick = (invoiceId) => {
    const invoice = jsonData.find((item) => item.id === invoiceId);
    setSelectedInvoice(invoice);
  };

  const handleCloseIconClick = () => {
    setSelectedInvoice(null);
  };

  const dateBetweenOperators = [
    {
      label: "between",
      value: "between",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
      },
      InputComponent: DateRangeInputValue,
      InputComponentProps: {},
      getValueAsString: (value) => `${value[0]}`,
    },
    {
      label: "is",
      value: "is",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
      },
      InputComponent: DateInputValue,
      getValueAsString: (value) => `${value[0]}`,
    },
  ];

  const columns = [
    {
      field: "invoice_number",
      headerName: "Invoice Id",
      sortable: false,
      width: 100,
      headerClassName: "super-app-theme--header",
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell: (params) => (
        <span className="ml-4 font-semibold ">{params.value}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span
          className={`text-xs font-semibold px-2.5 py-0.5 rounded border ${
            params.value === "SUCCESS"
              ? "text-green-700 border-green-400"
              : "text-moove-orange border-orange-400"
          }`}
        >
          {params.value.toUpperCase().replace("_", " ")}
        </span>
      ),
      filterable: false,
    },
    {
      field: "occurred_at",
      headerName: "Occurred At",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => formatDateInTimezone(params.value),
      filterable: true,
      filterOperators: dateBetweenOperators,
    },
    {
      field: "email",
      headerName: "User",
      sortable: false,
      width: 150,
      headerClassName: "super-app-theme--header",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
    },
    {
      field: "invoice_date",
      headerName: "Invoice Due Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => formatDateInTimezone(params.value),
      filterable: false,
    },
    {
      field: "invoice_amount",
      headerName: "Invoice Amount",
      width: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) =>
        formatCurrency(Number(params.value) / 100, params.row?.currency_code),
      filterable: false,
    },
    {
      field: "installment_start_date",
      headerName: "Installment Start Date",
      width: 180,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => formatDateInTimezone(params.value),
      filterable: false,
    },
    {
      field: "business_entity_id",
      headerName: "Business Entity Id",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "number_of_installments",
      headerName: "No. of installments",
      sortable: false,
      disableColumnMenu: true,
      width: 160,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "customer_id",
      headerName: "Customer Id",
      sortable: false,
      disableColumnMenu: true,
      width: 170,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "error_message",
      headerName: "Error",
      width: 100,
      headerClassName: "super-app-theme--header",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const isFailure = params.row.status.toUpperCase() === "FAILURE";
        return (
          <ViewIcon
            onClick={
              isFailure ? () => handleViewClick(params.row.id) : undefined
            }
            style={{
              cursor: isFailure ? "pointer" : "not-allowed",
              color: isFailure ? "inherit" : "gray",
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="flex-grow pl-5 pr-5 md:pl-10 md:pr-10 h-screen max-w-max overflow-y-auto">
      <TableViewAudit
        jsonData={jsonData}
        columns={columns}
        id={getRowId}
      ></TableViewAudit>
      <Dialog
        open={Boolean(selectedInvoice)}
        sx={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <DialogTitle
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          Error Message
          <IconButton
            aria-label="close"
            onClick={handleCloseIconClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          className="font-normal border-solid border-2 m-3 rounded-lg "
        >
          {selectedInvoice && (
            <div className="container">
              <div className="text-output">
                {renderText(selectedInvoice.error_message)}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Audits;
