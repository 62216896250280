import Avatar from "@mui/material/Avatar";
import { useEffect } from "react";
import { SlOptions } from "react-icons/sl";
import { useSelector, useDispatch } from "react-redux";
import { getUser, logOut } from "../../reducers/userReducer";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import { FaSignOutAlt } from "react-icons/fa";
export default function UserView() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUser());
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    dispatch(logOut());
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {user.loggedIn && (
        <>
          <div
            className="flex transform items-center rounded-lg px-3 py-2 text-gray-700 transition-colors duration-300 hover:bg-gray-300 hover:text-black"
            onClick={handleClick}
          >
            <Avatar
              alt={user.name}
              src={user.picture}
              sx={{ width: 24, height: 24 }}
            />
            <span className="ml-3 text-xs font-semibold inline">
              {user.name}
            </span>
            <SlOptions className="ml-4 font-semibold" />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className="ml-9"
          >
            <div className=" p-5 flex flex-col">
              <div className=" flex flex-row">
                <Avatar
                  alt={user.name}
                  src={user.picture}
                  sx={{}}
                  variant="square"
                />
                <div className="ml-3 flex flex-col">
                  <span className=" text-md font-semibold inline">
                    {user.name}
                  </span>
                  <span className=" text-xs  inline text-gray-600 font-semibold">
                    {user.email}
                  </span>
                </div>
              </div>
              <div
                className="mt-4 p-2 flex flex-row  rounded-lg text-gray-700 transition-colors duration-300 hover:bg-gray-300 hover:text-black hover:cursor-pointer"
                onClick={signOut}
              >
                <FaSignOutAlt className="my-auto"> </FaSignOutAlt>
                <span className="ml-3 text-sm font-semibold">Sign out</span>
              </div>
            </div>
          </Popover>
        </>
      )}
    </>
  );
}
