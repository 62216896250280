import './App.css';
import {
  BrowserRouter as Router, Route, Routes, NavLink,
} from 'react-router-dom';
import { TbReportSearch, TbListDetails } from 'react-icons/tb';
import { LiaFileInvoiceSolid } from 'react-icons/lia';
import { MdSchedule } from 'react-icons/md';
import React from 'react';
import Invoice from './pages/Invoice/InvoiceView';
import AuditReport from './pages/Audit/AuditReportView';
import EnrollInvoiceForm from './pages/Invoice/EnrollInvoiceView';
import ScheduledInstallments from './pages/Audit/ScheduledInstallmentsView';
import InstallmentDetails from './pages/Audit/InstallmentDetailsView';
import UserView from './components/User/UserView';
import SnackBar from './components/Snack/SnackView';
import { ReactComponent as MooveLogo } from './logo.svg';
import { TIME_ZONE } from './utils/timezone';

function App() {
  return (
    <Router>
      <div className="flex flex-col sm:flex-row">
        <aside className="sm:w-60 flex-none h-screen flex flex-col overflow-y-auto border-r bg-white px-5 py-8 shadow-2xl">
          <a href="/" aria-label="MooveLogo" className="mb-10 p-7">
            <MooveLogo />
          </a>

          <div className="mt-6 flex flex-1 flex-col font-medium justify-between">
            <nav className="-mx-3 space-y-6 ">
              <div className="space-y-5 font-small">
                <NavLink
                  className="flex transform items-center rounded-lg px-3 py-2 text-black transition-colors duration-300 hover:bg-gray-300 hover:text-black"
                  to="/"
                >
                  <LiaFileInvoiceSolid
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                  <span className="mx-4 text-xl ">Invoices</span>
                </NavLink>
                <NavLink
                  className="flex transform items-center rounded-lg px-3 py-2 text-black transition-colors duration-300 hover:bg-gray-300 hover:text-black"
                  to="/audits"
                >
                  <TbReportSearch className="h-6 w-6" aria-hidden="true" />
                  <span className="mx-4 text-xl ">Audits</span>
                </NavLink>
                <NavLink
                  className="flex transform items-center rounded-lg px-3 py-2 text-black transition-colors duration-300 hover:bg-gray-300 hover:text-black"
                  to="/scheduled_installments"
                >
                  <MdSchedule className="h-6 w-6" aria-hidden="true" />
                  <span className="mx-4 text-xl ">
                    Scheduled Installments
                  </span>
                </NavLink>
                <NavLink
                  className="flex transform items-center rounded-lg px-3 py-2 text-black transition-colors duration-300 hover:bg-gray-300 hover:text-black"
                  to="/installment_details"
                >
                  <TbListDetails className="h-6 w-6" aria-hidden="true" />
                  <span className="mx-4 text-xl ">Installment Details</span>
                </NavLink>
              </div>
            </nav>
            <div className="mt-auto pt-5 pl-3 font-light text-sm">
              <span>
                TimeZone :
                {TIME_ZONE}
              </span>
            </div>
            <UserView />
          </div>
        </aside>
        <Routes className="flex-grow">
          <Route path="/" element={<Invoice />} />
          <Route path="/audits" element={<AuditReport />} />
          <Route path="/invoice/:id" element={<EnrollInvoiceForm />} />
          <Route
            path="/scheduled_installments"
            element={<ScheduledInstallments />}
          />
          <Route
            path="/installment_details"
            element={<InstallmentDetails />}
          />
        </Routes>
      </div>
      <SnackBar />
    </Router>
  );
}

export default App;
