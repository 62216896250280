import moment from 'moment-timezone';

export const TIME_ZONE = process.env.TZ || 'Europe/London';
export const DATE_FORMAT = 'DD-MMM-YYYY HH:mm';

export const getDateInTimezone = (timezone = TIME_ZONE) => {
  const dateInTimezone = moment.tz(timezone).toDate();
  return dateInTimezone;
};

export const getTimeInMilliseconds = (
  timezone = TIME_ZONE,
  date = getDateInTimezone(),
) => {
  const timeInTimezone = moment.tz(date, timezone);
  const milliseconds = timeInTimezone.valueOf();
  return milliseconds;
};

export const formatDateInTimezone = (milliseconds, timezone = TIME_ZONE) => {
  const momentObject = moment(milliseconds);
  const momentInTimezone = momentObject.tz(timezone);
  const formattedDate = momentInTimezone.format(DATE_FORMAT);
  return formattedDate;
};

export const convertTimeZone = (date) => {
  const momentObj = moment(date);
  const changedTimeZone = momentObj.clone().tz(TIME_ZONE, true);
  return changedTimeZone.valueOf();
};

export const convertToMilliseconds = (dateTimeString) => {
  const selectedDate = new Date(dateTimeString);
  return getTimeInMilliseconds(null, selectedDate);
};
