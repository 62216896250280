import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings/dev";
import { setLoading } from "./invoiceReducer";
import httpRequest from "../utils/api";
import { displayError } from "./snackBarReducer";
/**
 * INSTALLMENT DETAILS FORMAT:
 * 
    {
      "id": 8,
      "invoice_number": "872",
      "invoice_config_id" : "" , 
      "installment_id" : "" , 
      "installment_date" : "" , 
      "installment_amount" : "" , 
      "status" : "upcoming_installment/installment_attempt_failed/installment_attempt_success",
      "cb_transaction_id" : "",
      "cb_transaction_status" : ""
    }
 */
const initialState = {
  loading: true,
  offset: null,
  audits: [],
  length: 0,
  offsetList: [null],
};

const installmentDetailsReducer = createSlice({
  name: "installmentDetails",
  initialState,
  reducers: {
    setInstallments: (state, action) => {
      state.audits = action.payload.list;
      state.loading = false;
      state.offset = action.payload.next_offset;
      state.length = state.audits.length;
      if (!state.offsetList.includes(action.payload.next_offset))
        state.offsetList.push(action.payload.next_offset);
      return state;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    removeOffset: (state, action) => {
      if (action.payload < state.offsetList.length)
        state.offsetList = state.offsetList.slice(0, -1);
    },
    setOffsetList: (state, action) => {
      state.offsetList = action.payload;
    },
  },
});

    export const listInstallmentDetails =
    (filter = [], offset = null) =>
    async (dispatch) => {
      try {
        var url = `${baseURL}/listInstallmentReports?`;
        if (filter && filter.length > 0) {
          filter.forEach((item) => {
        url += `${item.key}=${encodeURIComponent(item.value)}&`;
      });
        }
        if (offset != null) {
          url = url + `&next_offset=${offset}`;
        }

        dispatch(setLoading(true));
        const response = await httpRequest("get", url);
        const data = response;
        dispatch(setInstallments(response));
        return data;
      } catch (error) {
        dispatch(
          displayError(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "something went wrong"
          )
        );
      }
    };

export const { setInstallments, removeOffset, setOffsetList } =
  installmentDetailsReducer.actions;

export default installmentDetailsReducer.reducer;
