import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createInstallment,
  retrieveInvoice,
} from "../../reducers/invoiceReducer";
import { displayError } from "../../reducers/snackBarReducer";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../reducers/userReducer";
import {
  TIME_ZONE,
  convertTimeZone,
  getDateInTimezone,
} from "../../utils/timezone";
import { formatCurrency } from "../../utils/currencyUtil";
import moment from "moment-timezone";

const dateInTimezone = moment.tz(TIME_ZONE).format("YYYY-MM-DDTHH:mm");

function EnrollInvoiceForm() {
  const { id } = useParams();
  const invoice = useSelector((state) => state.invoice);
  const paymentDue = invoice ? invoice.amount_due : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_email = useSelector((state) => state.user.email);
  const [formData, setFormData] = useState({
    field1: id,
    field2: paymentDue,
    no_of_installments: 3,
    start_date: dateInTimezone,
    user_email: "",
  });

  const [timeFrame, setTimeFrame] = useState("upcoming-wednesday");

  useEffect(() => {
    dispatch(retrieveInvoice(id));
  }, [dispatch, id]);

  useEffect(() => {
    // Fetch user details when the component mounts
    dispatch(getUser());
  }, [dispatch]);

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const getNextWednesday = () => {
    const today = getDateInTimezone();
    const dayOfWeek = today.getDay();
    const daysUntilWednesday = dayOfWeek <= 3 ? 3 - dayOfWeek : 10 - dayOfWeek;
    const nextWednesday = new Date(today);
    nextWednesday.setDate(today.getDate() + daysUntilWednesday);
    return nextWednesday;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    async function submit() {
      try {
        var start_Date;
        var installment_type;
        if (timeFrame === "upcoming-wednesday") {
          const nextWednesday = getNextWednesday();
          const londonDate = nextWednesday.toLocaleString("en-US", {
            timeZone: "Europe/London",
          });
          const date = new Date(londonDate).getTime();
          start_Date = convertTimeZone(date);
          installment_type = "upcoming_wednesday";
        } else if (timeFrame === "immediately") {
          start_Date = convertTimeZone(formData.start_date);
          installment_type = "immediately";
        } else {
          start_Date = convertTimeZone(formData.start_date);
          installment_type = "future";
        }
        dispatch(
          createInstallment(id, {
            no_of_installments: formData.no_of_installments,
            start_date: start_Date,
            user_email: user_email,
            installment_type: installment_type,
          })
        );
        navigate("/");
      } catch (error) {
        dispatch(displayError(error));
      } finally {
      }
    }
    submit();
  };

  return (
    <div className="flex min-h-full flex-1 flex-col flex-basis justify-top px-6 py-12 lg:px-8 mt-14">
      <div className="flex flex-col lg:flex-row lg:justify-between items-center mb-6">
        <p className="font-semibold text-4xl text-gray-700">Enroll Invoice</p>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center lg:justify-start mt-4 lg:mt-0 space-x-4">
            {" "}
            <Link
              to="/"
              className="text-moove-orange font-semibold bg-orange-200 border border-moove-orange py-2 px-4 rounded-full w-30 lg:w-auto hover:bg-orange-100"
            >
              Dismiss
            </Link>
            <button className="rounded-full bg-moove-orange py-2 px-4 font-semibold text-white hover:bg-orange-500">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="border-b border-moove-orange mt-8"></div>
      <div className="bg-white rounded p-4 md:p-8 mb-6 mx-auto mt-10">
        <div className="grid text-sm grid-cols-1">
          <div className="lg:col-span-2">
            <div className="grid gap-4 gap-y-2 text-base grid-cols-1 md:grid-cols-5">
              <div className="md:col-span-5">
                <label htmlFor="field1" className="font-medium">
                  Invoice Id
                </label>
                <input
                  type="text"
                  name="field1"
                  id="field1"
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 font-medium"
                  value={id}
                  disabled
                />
              </div>

              <div className="md:col-span-5 mt-4">
                <label htmlFor="field2" className="font-medium">
                  Amount Due
                </label>
                <input
                  type="text"
                  name="field2"
                  id="field2"
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 font-medium"
                  value={
                    invoice.amount_due != null
                      ? formatCurrency(
                          Number(invoice.amount_due) / 100,
                          invoice.currency_code
                        )
                      : ""
                  }
                  disabled
                />
              </div>

              <div className="md:col-span-5 mt-4">
                <label htmlFor="no_of_installments" className="font-medium">
                  No of Installments
                </label>
                <select
                  type="number"
                  name="no_of_installments"
                  id="no_of_installments"
                  className="h-10 border mt-1 rounded px-4 w-full border-gray-300 font-medium"
                  value={formData.no_of_installments}
                  onChange={handleInputChange}
                  required
                >
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>

              <div className="md:col-span-5 mt-4 flex flex-col">
                <label htmlFor="start_date" className="font-medium">
                  Start Date
                </label>
                <select
                  id="time-frame-select"
                  value={timeFrame}
                  onChange={handleTimeFrameChange}
                  className="h-10 border mt-1 rounded w-full border-gray-300 font-medium"
                >
                  <option value="upcoming-wednesday">
                    Upcoming Wednesday{" "}
                    <span style={{ color: "gray" }}>
                      (
                      {getNextWednesday().toLocaleString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        timeZone: TIME_ZONE,
                      })}
                      )
                    </span>
                  </option>
                  <option value="immediately">Immediately </option>
                  <option value="choose-date">Choose Date</option>
                </select>
                {timeFrame === "choose-date" && (
                  <input
                    type="datetime-local"
                    name="start_date"
                    id="start_date"
                    className="h-10 border mt-1 rounded px-4 w-full font-medium border-gray-300"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    placeholder={dateInTimezone}
                    min={dateInTimezone}
                    required
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollInvoiceForm;
