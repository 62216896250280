import React from "react";
import TableView from "../../components/Invoice/InvoiceTableView";
import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { formatCurrency } from "../../utils/currencyUtil";
import IconButton from "@mui/material/IconButton";
import { formatDateInTimezone } from "../../utils/timezone";
import { DatePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

function DateInputValue(props) {
  const { item, applyValue } = props;
  const [selectedDate, setSelectedDate] = React.useState(item.value);

  const handleDateChange = (event) => {
    setSelectedDate(event);
    applyValue({ ...item, value: event });
  };

  return (
    <>
      <DatePicker
        value={selectedDate}
        format="yyyy-MM-dd"
        editable={false}
        onChange={handleDateChange}
        className="mt-3"
      />
    </>
  );
}

function Invoice() {
  const getRowId = (row) => row.id;

  const dateBetweenOperators = [
    {
      label: "is",
      value: "is",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
      },
      InputComponent: DateInputValue,
      getValueAsString: (value) => `${value[0]}`,
    },
  ];

  const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 65,
    border: '1px solid #dadde9',
  },
}));

  const columns = [
    {
      field: "id",
      headerName: "Invoice Id",
      width: 147,
      headerClassName: "super-app-theme--header",
      hideable: false,
      sortable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell: (params) => (
        <div className="flex items-center">
          <span className="font-semibold">{params.value}</span>
          {params.row.is_already_enrolled && (
           <LightTooltip title="Already Enrolled" placement="right" slotProps={{
        popper: {
          sx: {  
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
              {
                marginLeft: '1px',
              },
          },
        },
      }}>
            <InfoIcon sx={{ fontSize: 16 }} className=" ml-2 cursor-pointer text-moove-orange" />
        </LightTooltip>
        )}
        </div>
      ),
    },
    {
      field: "status",
      headerName: " Status",
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span className=" text-moove-orange text-xs font-semibold  px-2.5 py-0.5 rounded dark:text-moove-orange border border-orange-400">
          {params.value.toUpperCase().replace("_", " ")}
        </span>
      ),
      filterable: false,
    },
    {
      field: "date",
      headerName: "Issued on",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => formatDateInTimezone(params.value * 1000),
      filterable: true,
      filterOperators: dateBetweenOperators,
    },
    {
      field: "amount_due",
      headerName: " Amount Due",
      width: 150,
      valueFormatter: (params) => Number(params.value).toLocaleString(),
      headerClassName: "super-app-theme--header",
      filterable: false,
      renderCell: (params) => (
        <span className=" text-moove-orange text-md font-semibold">
          {formatCurrency(Number(params.value) / 100, params.row.currency_code)}
        </span>
      ),
      disableExport: true,
    },
    {
      field: "customer_id",
      headerName: "Customer Id",
      width: 200,
      sortable: false,
      headerClassName: "super-app-theme--header",
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
    },
    {
      field: "subscription_id",
      headerName: "Subscription Id",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "business_entity_id",
      headerName: "Business Entity Id",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) =>
        !params.row.is_already_enrolled ? (
          <Link to={`/invoice/${params.row.id}`} className="text-gray-800">
            <IconButton>
              <FaRegEdit className="text-sm text-black" />
            </IconButton>
          </Link>
        ) : (
          <Tooltip title="Already enrolled">
            <IconButton>
              <FaRegEdit className="opacity-50 cursor-not-allowed text-sm" />
            </IconButton>
          </Tooltip>
        ),
    },
  ];
  return (
    <div className="flex-grow pl-5 pr-5 md:pl-10 md:pr-10 h-screen max-w-max overflow-y-auto">
      <TableView
        columns={columns}
        id={getRowId}
      ></TableView>
    </div>
  );
}

export default Invoice;
