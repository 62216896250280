import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings/dev";
import httpRequest from "../utils/api";
import { displayError, displaySuccess } from "./snackBarReducer";

/**
 * INVOICE FORMAT
 * {
     id: "inv_124",
     customer_id: "test-rs",
     subscription_id: "16CRs0U2kUOKN1zAE",
     status: "Paid",
     date: 1704188079,
     total: 5000,
     amount_due: 4000,
   }
 */
const listInvoiceInitialState = {
  loading: true,
  offset: null,
  invoices: [],
  length: 0,
  offsetList: [null],
};

const invoiceInitialState = {};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState: listInvoiceInitialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload.list.map((item) => item.invoice);
      state.loading = false;
      state.offset = action.payload.next_offset;
      state.length = state.invoices.length;
      if (!state.offsetList.includes(action.payload.next_offset))
        state.offsetList.push(action.payload.next_offset);
      return state;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    removeOffset: (state, action) => {
      if (action.payload < state.offsetList.length)
        state.offsetList = state.offsetList.slice(0, -1);
    },
    setOffsetList: (state, action) => {
      state.offsetList = action.payload;
    },
  },
});

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: invoiceInitialState,
  reducers: {
    setInvoice: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const listInvoices =
  (filterKey = null, filterValue = null, offset = null) =>
  async (dispatch) => {
    var url = `${baseURL}/listInvoices?`;
    if (filterKey && filterValue) {
      url = url + `${filterKey}=${filterValue}`;
    }
    if (offset != null) {
      url = url + `&next_offset=${offset}`;
    }

    dispatch(setLoading(true));
    const response = await httpRequest("get", url);
    const data = response.list.map((item) => item.invoice);
    dispatch(setInvoices(response));
    return data;
  };

export const createInstallment = (invoiceId, payload) => async (dispatch) => {
  try {
    var url = `${baseURL}/${invoiceId}/enrollInvoice`;

    const response = await httpRequest("post", url, payload);
    const data = response;
    dispatch(
      response.enrolled_or_scheduled === "SCHEDULED"
        ? displaySuccess("Installment Scheduled: " + response.installment_id)
        : displaySuccess("Installment Enrolled: " + response.installment_id)
    );
    return data;
  } catch (error) {
    dispatch(
      displayError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "something went wrong"
      )
    );
    return error;
  }
};

export const retrieveInvoice = (invoiceId) => async (dispatch) => {
  try {
    var url = `${baseURL}/retrieveInvoice/${invoiceId}`;
    const response = await httpRequest("get", url);
    dispatch(setInvoice(response.invoice));
    return response;
  } catch (error) {
    dispatch(
      displayError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "something went wrong"
      )
    );
  }
};

export const { setInvoices, setLoading, removeOffset, setOffsetList } =
  invoicesSlice.actions;
export const { setInvoice } = invoiceSlice.actions;

export const invoicesReducer = invoicesSlice.reducer;
export const invoiceReducer = invoiceSlice.reducer;
